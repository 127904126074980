<template>
  <div class="register pb-5">
    <div class="container-lg">
      <div class="row mb-3">
        <div class="col-sm-12">
          <img src="@/assets/img/reg-banner-.png" class="img-fluid" alt="registration banner" />
        </div>
      </div>
      <h1 class="text-center"> Payment Failed</h1>
    </div>
  </div>
</template>

<script>

export default {
  name: "canceled",
 
};
</script>

<style lang="scss" scoped>
.register {
  & label {
    font-weight: 500;
  }

  & .sessions {
    & .session {
      & .card {
        border-radius: 50px !important;
      }

      label {
        font-weight: 700;
      }
    }
  }
}
</style>
